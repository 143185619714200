import React from 'react';
import { Grid, Paper, Typography, Divider, MenuItem, Select, FormControl, Checkbox, Button, FormControlLabel, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { ButtonOne } from '../../common/ButtonOne';
import styled from '@emotion/styled';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { HeadingOne } from '../../common/HeadingOne';

const CheckBoxConatiner = styled(Box)({
  display: 'flex', 
  alignItems: 'flex-start',
  marginTop: '2px',
});

const paperStyles = {
  backgroundColor: "orderSummaryGird.main",
  padding: 2,
};

const smallFonts = {
  fontSize: '12px'
};

const PaymentGrid = ({label, value}) => {
  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={6}>
        <Typography variant="body1" display='flex' fontWeight={600}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" align="right" fontWeight={600}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const OrderSummaryGrid = ({label, value}) => {
  return (
        <Grid container sx={{ marginBlock:1 }}>
            <Grid item xs={2} sm={2}>
              <Typography variant="body1">1x</Typography>
            </Grid>
            <Grid item xs={6} sm={7}>
              <Typography variant="body1">{label}</Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="body1" align="right">
                {value}
              </Typography>
            </Grid>
        </Grid>
     )
}

const ProductItem = ({ imageSrc, productName }) => {
  return (
    <>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ maxWidth: { sm: '50px', md: '100px'} }}>
                <img src={imageSrc} alt={productName} style={{ width: '100%' }} />
              </Box>
              <Box sx={{ width:'100%', paddingLeft: '10px' }}>
                <Typography variant="span" color="secondary" fontWeight="bold" 
                  sx={{ fontSize: { xs: '18px', sm: '16px', md: '20px'} }}>
                  {productName}
                </Typography>
                <Divider style={{ margin: '8px 0' }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems={'center'}>
          <Grid item xs={8} sx={{ display: 'flex', textAlign: 'left' }}>
            <Typography variant="body1" fontWeight={600} sx={{ display: 'flex', textAlign: 'left' }}>
              <Typography variant="span" fontWeight="bold" margin={'auto'}>
                Quantity
              </Typography>
              <FormControl variant="outlined" size="small">
                <Select defaultValue={1} labelId="demo-select-small-label" style={{ marginLeft: '10px', marginRight: '20px' }}>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </FormControl>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" align="right" color="secondary" fontWeight={600}>
              $999 USD
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container>
          <Grid item xs={8} textAlign={'start'}>
            <Typography variant="span" fontWeight="bold" >
              Quantity
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select defaultValue={1} labelId="demo-select-small-label" style={{ marginLeft: '10px', marginRight: '20px' }}>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} textAlign={'end'}>
            <Typography variant="span" fontWeight="bold" color="secondary">
              $999 USD
            </Typography>
          </Grid>
        </Grid> */}
    </>
  );
};

export default function OrderSummary1() {
  const theme = useTheme();
  return (
    <Grid container className='max-center-cont'>
    <Box sx={{ flexGrow: 1 , marginTop: 3}}>
      <HeadingOne color="secondary" sx={{ textAlign: 'center' }}
        text="Order Summary" />
      <Typography variant="body1" mb={6} mt={1} textAlign={'center'} sx={smallFonts}>
        Please review the details and proceed to the payment
      </Typography>
      <Grid container spacing={2}>
        {/* Product Grid */}
        <Grid item xs={12} sm={4}>
          <Paper sx={paperStyles}>
            <Typography variant="h5" mb={2} fontWeight={'bold'} color="secondary">
              Product
            </Typography>
            <ProductItem imageSrc="./c-box.png" productName="Ninja Caster Pro"/>
            {/* Horizontal line */}
            <Divider style={{margin: '16px 0' }} />
            {/* Subscription box */}
            <Box sx={{  p: '10px', color: 'white.main', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: theme.palette.secondary.main }}>
              <Box sx={{ flexDirection: 'column'}}>
                <Typography variant="h6">
                  Subscription
                </Typography>
                <Typography variant="body1" sx={smallFonts}>per month $30 USD</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="body1" mr={2}>
                      Choose Month
                    </Typography>
                    <FormControl variant="outlined">
                    <Select defaultValue={1} labelId="demo-select-small-label" sx={{ color: 'white.main', '& .MuiSelect-icon': { color: 'white.main' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white.main' } }} size="small">
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
              </Box>
              <Grid item>
                <Checkbox color="default" sx={{ color: 'white.main' }} />
              </Grid>
          </Box>
            {/* Match Highlights box */}
            <Box mt={2} p={1} borderRadius={2} bgcolor="gray.main" sx={{ display: 'flex',justifyContent: 'space-between'}}>
              <Box sx={{flexDirection: 'column'}}>
                <Typography variant="h6" fontWeight="bold">
                  Match Highlights
                </Typography>
                <Typography variant="body1" sx={smallFonts}>per month $50 USD</Typography>
              </Box>
              <Checkbox color="default" style={{ color: theme.palette.grayCheckBox.main  }} />
            </Box>
            <Typography variant="body1" mt={2} sx={smallFonts} color={'productfont.main'}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={paperStyles}>
            <Typography variant="h5" marginBottom={2} color="secondary" fontWeight= "bold">
              Delivery Address
            </Typography>
            <Typography variant="h6" marginBottom={1} color= "secondary">
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth label="Full Name" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Email" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Mobile Number" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Sports Type" variant="standard" />
              </Grid>
            </Grid>
            <Typography variant="h6" marginTop={4} color="secondary">
              Shipping Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth label="Flat, House No." variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Building, Apartment, House Name" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Street Address" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Postal/Zip Code" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="City" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-standard-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="State"
                  >
                    <MenuItem value={'state1'}>State 1</MenuItem> 
                    <MenuItem value={'state2'}>State 2</MenuItem>
                    <MenuItem value={'state3'}>State 3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} pb={3}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Country"
                  >
                    <MenuItem value={'country1'}>Country 1</MenuItem> 
                    <MenuItem value={'country2'}>Country 2</MenuItem>
                    <MenuItem value={'country3'}>Country 3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={paperStyles} style={{marginBottom:'15px'}}>
            <Typography variant="h5" marginBottom={2} color="secondary" fontWeight="bold">
              Order Summary
            </Typography>
            {/* Summary Item */}
            <OrderSummaryGrid label="Ninja Caster Pro" value="$999 USD" />
            <OrderSummaryGrid label= {
              <>
                Subscription <span style={{ opacity: 0.7 }}>(one month)</span>
              </>
            } value="$30 USD" />
            {/* Summary Item */}
            <Divider />
              {/* Summary Item */}
              <Box style={{ margin:'15px 0' }}>
                <PaymentGrid label="Subtotal" value="$1029 USD" />
                <PaymentGrid label="Shipping fee" value="$0 USD" />
                {/* Summary Item */}
                <PaymentGrid label={
                    <> 
                      GST &nbsp; <ErrorOutlineIcon sx={{ fontSize: '1rem', marginTop: '4px' }} />
                    </>
                } value="$0 USD" />
              </Box>
              <Divider />
              {/* Summary Item */}
              <Box style={{ margin:'10px 0' , fontWeight: "bold" , color: theme.palette.secondary.main }} >
              <PaymentGrid label="Grand Total" value="$1029 USD" />
              </Box>
              <Typography
                variant="caption"
                style={{ color: theme.palette.orderSummaryfont.main, fontSize: '14px'}}
              >
                Shipping to World wide
                <p style= {{margin:0}}>DHL Express (5 to 10 business days)</p>
              </Typography>
            </Paper>
            <CheckBoxConatiner>
              <Checkbox color="default" sx={{color:'secondary.main'}}/>
              <Typography variant="body1" sx={{mt: '7px'}}>
                Email me about new products, deals, and offers in your email.
              </Typography>
            </CheckBoxConatiner>
            <CheckBoxConatiner>
              <Checkbox color="default" sx={{color:'secondary.main'}} />
              <Typography variant="body1" sx={{mt: '7px'}}>
                By signing up or placing an order, you're consenting to our{' '}
                <Typography variant="body1" component="span" style={{textDecoration: 'underline' }} color="secondary">
                  terms & conditions privacy policy.
                </Typography>
              </Typography>  
            </CheckBoxConatiner>
            <Box textAlign='center' mt={4}>
              <ButtonOne text="Proceed to Pay" onClick={() => { }}/>
            </Box>
          </Grid>
      </Grid>
    </Box>
    </Grid> 
  );
}
import './App.css';
import * as React from 'react';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { AppComponent } from './components/App';

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      
    },
    secondary: {
      main: '#FB4747',
    },
    white: {
      main: '#FFFFFF',
    },
    lightGray: {
      main: '#F2F2F2',
    },
    gray: {
      main: '#E1E1E1',
    },
    grayShade: {
      main: '#C9C9C9',
    },
    orderSummaryGird: {
      main: '#F6F6F6',
    },
    grayCheckBox: {
      main: '#A7A7A7'
    },
    orderSummaryfont: {
      main: '#606060'
    },
    productfont:{
      main: '#AFAFAF'
    }
  },
  typography: {
        // fontFamily: 'Product Sans',
        // secondary: {
        //   fontFamily: 'Adieu',
        // },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}> <AppComponent /> </ThemeProvider>
    </div>
  );
}

export default App;

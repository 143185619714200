import { Button } from '@mui/material';
import * as React from 'react';

export const ButtonOne = (props) => {
    let BtnStyle = {
        textTransform: 'capitalize',
        minWidth: '150px',
        borderRadius: '11px',
        borderWidth: 2,        
        fontFamily: 'Product-Sans-Bold',
    }

    if (props.theme === 'invert') {
        // button colors should be inverted
        BtnStyle = {...BtnStyle, ...{
            borderColor: '#FFF',
            color: '#FFF'
        }}
    }
     else {
        BtnStyle = {...BtnStyle, ...{
            borderColor: '#FB4747',
            backgroundColor: '#fff',
            color: '#FB4747',
        }}
    }
    if (props.theme === 'active') {
        // button colors should be inverted
        BtnStyle = {...BtnStyle, ...{
            borderColor: '#FB4747',
            color: '#FFF',
            backgroundColor: '#FB4747',
        }}
    }
    if (props.style) {
        BtnStyle = {...BtnStyle, ...props.style}
    }

    return (
        <Button
            variant="outlined"
            style={BtnStyle}
            onClick={props.onClick}
        >
            {props.text}
            
        </Button>
    )
}
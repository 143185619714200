import * as React from 'react';
import { HomeContainer1 } from '../HomeContainer1';
import { Box } from '@mui/material';
import { HomeContainer2 } from '../HomeContainer2';
import { HomeContainer3 } from '../HomeContainer3';
import { HomeContainer4 } from '../HomeContainer4';
import { HomeComponent5 } from '../HomeContainer5';
import Footer from '../Footer';
import './index.css';

export const HomeContainer = () => {

    const boxContainer = (() => ({
        flexGrow: 1, mt: 10
    })) 

    return (
        <>
            <Box sx={boxContainer}>
                <HomeContainer1 />
                <HomeContainer2 />
                <HomeContainer3 />
                <HomeContainer4 />
                <HomeComponent5 />
                <Footer />
            </Box>
        </>
    )
}
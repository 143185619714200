import './index.css';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { ButtonOne } from '../ButtonOne';
import { useNavigate } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import InfoIcon from '@mui/icons-material/Info';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useLocation} from 'react-router-dom';
import Grid from '@mui/material/Grid';

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = React.useState(location.pathname);
  const [anchorEl, setAnchorEl] = React.useState();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const navigateToURL = (url) => {
    navigate(url, { replace: true });
  }

  const menuId = 'primary-search-account-menu';

  const [state, setState] = React.useState({
    right: false,
  });
  
  const toggleDrawer =
    (anchor, open) =>
    (event) => {
      if (event && event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const pages = [
    { name: 'Home', path: '/', icon: <MenuOpenIcon /> },
    { name: 'Ninja Caster', path: '/product', icon: <img src="/ninjaCaster.png" style={{ width: '28px', height: '28px', marginTop: '4px' }} /> },
    // { name: 'About us', path: '/#', icon: <InfoIcon /> },
    { name: 'Contact us', path: '/contact', icon: <ContactSupportIcon /> },
    { name: 'Order Now', path: '/orders', icon: <InventoryIcon />, isButton: true }
  ]

  const list = () => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer('right', false)}
        onKeyDown={toggleDrawer('right', false)}
      >
        <List>
          <ListItem key={0}>
            <img src="/ninja-caster.png" style={{ width: '130px', cursor: 'pointer'}}
                onClick={() => navigateToURL(`/`)}/>
          </ListItem>
          <Divider />
          {pages.map((page, index) => (
            <ListItem key={page.name} disablePadding sx={{fontFamily: 'Product-Sans-Regular'}}>
              <ListItemButton onClick={() => navigateToURL(page.path)} sx={{ textTransform: 'capitalize', fontFamily: 'Product-Sans-Regular', }}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  {page.icon}
                </ListItemIcon>
                <ListItemText primary={page.name} sx={{
                  color: activePage === page.path ? 'secondary.main' : 'inherit',
                  textTransform: 'capitalize',
                  fontFamily: 'Product-Sans-Regular'
                }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <SwipeableDrawer
      anchor={'right'}
      open={state['right']}
      onClose={toggleDrawer('right', false)}
      onOpen={toggleDrawer('right', true)}
    >
        {list('right')}
    </SwipeableDrawer>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ background: 'white', color: 'black',borderBottom: '1px solid rgba(128, 128, 128, 0.3)', boxShadow: 'none',  py: 2,}}>
        <Toolbar sx={{ paddingRight: { xs: '5px', md: '24px' } }}>
        <Grid container justifyContent="space-between" alignItems="center" maxWidth={1170} marginX={'auto'}>
          <Grid item xs={3}>
            <img src="/ninja-caster.png" style={{ width: '230px', cursor: 'pointer'}} onClick={() => navigateToURL(`/`)}/>
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ display: { xs: 'none', md: 'flex'} }}>
              {pages.map((page, index) => (
                page.isButton ?activePage=== page.path?

                  <ButtonOne text="Order Now" theme="active" onClick={() => navigateToURL(page.path)} style={{marginLeft: '20px',}} />
                  : <ButtonOne text="Order Now"  onClick={() => navigateToURL(page.path)} style={{marginLeft: '20px',}} />
                :
                  <Button
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{ mx: 1.5,
                        color: activePage === page.path ? 'secondary.main' : '#515151',
                        textTransform: activePage === page.path ? 'capitalize' : 'capitalize',
                        fontSize: activePage === page.path ? 17 : 17,
                        fontFamily: activePage === page.path ? 'Product-Sans-Bold' : 'Product-Sans-Regular',
                        whiteSpace: 'nowrap',
                        '@media (max-width: 1199px)': {px: 0.5,}
                      }}
                      onClick={() => navigateToURL(page.path)}
                  >{page.name}</Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={toggleDrawer('right', true)}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>          
        </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}

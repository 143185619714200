import React, { useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { Box, Grid, Paper, TextField, Typography, FormHelperText } from '@mui/material';
import { ButtonOne } from '../../common/ButtonOne';
import BusinessIcon from '@mui/icons-material/Business';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';
import { HeadingOne } from '../../common/HeadingOne';
import { getByUser } from '../../../service/api.service';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

const WhiteUnderlineTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFFFFF', // White border color for unfocused state
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFFFFF', // White border color for hover state
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFFFFF', // White border color for focused state
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#FFFFFF', // White border color for hover state
    },
  },
})(TextField);

const Container = styled(Box)({
  flexGrow: 1,
  paddingBlock: 25
});

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  text: {
    marginBottom: theme.spacing(6),
    lineHeight: '27px',
    fontSize: '18px',fontFamily: 'Product-Sans-Regular'
  },
  mediumBox: {
    padding: '5%',
    borderRadius: '10px',
    backgroundColor: '#FB4747',
    color:'#FFF',
  },
  redBackground: {
    background: 'linear-gradient(to top, #EB3131, #C90202)',
    borderRadius: '10px',
  },
  content: {
    
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'flex-start', // Align items at the top
    marginTop: theme.spacing(2), // Add margin to separate from the heading
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  background: {
    background: `url('/contactbg.png') no-repeat`,
    backgroundSize: 'contain', // Adjust as needed
    backgroundPosition: 'top right', // Adjust as needed
  },
}));

export const Orders = () => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
    purpose: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
    purpose: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [submissionError, setSubmissionError] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSuccessMessage('');
    setSubmissionError('');

    const errors = {};
    if (!formData.name) {
      errors.name = 'Full name is required.';
    }
    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address.';
    }
    if (!formData.mobile) {
      errors.mobile = 'Contact number is required.';
    }
    if (!formData.message) {
      errors.message = 'Message is required.';
    }
    if (!formData.purpose) {
      errors.purpose = 'Purpose is required.';
    }

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      // If there are errors, do not submit the form.
      return;
    }

    try {
      setIsSubmitting(true); 
      const messageWithContact = `${formData.message} - Phone Number: ${formData.mobile}`;
  
      const response = await getByUser({
        ...formData,
        message: messageWithContact, // Use the concatenated message
      });
  
      if (response.data) {
        setSuccessMessage('Request sent successfully.');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          message: '',
          purpose: '',
        });
      } else {
        setSubmissionError('An error occurred during submission.');
      }
    } catch (error) {
      console.error('API error:', error);
      setSubmissionError('An error occurred during submission.');
    } finally {
      setIsSubmitting(false); // Stop submitting, whether success or error
    }
  };
  return (
    <Box className={classes.background}>
      <Grid container justifyContent={'center'} maxWidth={1170} marginX={'auto'} sx={{marginTop: '89px', paddingTop: '100px', '@media (max-width: 1199px)': {paddingTop: '50px', px: 3}} }>
        <Grid item textAlign={'center'} lg={6}>
          <HeadingOne color="secondary" sx={{fontSize: '2.8rem',mb: 5, fontFamily: 'Adieu-Regular', '@media (max-width: 540px)': {fontSize: '2rem', }}} text="Become part of the Live Streaming revolution" />
          <Box className={classes.text}>Fill the form below and our live streaming expert will assist you.<br/>
            
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} maxWidth={1170} marginX={'auto'} sx={{my: 7, '@media (max-width: 1199px)': {my: 4, px: 3}} }>
        <Grid item sm={12} lg={4}>
          <Box sx={{textAlign: 'left',}}>
          <HeadingOne color="inherit" sx={{fontFamily: 'Adieu-Regular', fontWeight: 'normal', mb: 4}}
            text= {
              <>
              Get in Touch <br/>
              with us
              </>
            }/>
          <Box className={classes.iconTextContainer}>
                <BusinessIcon className={classes.icon} />
                <Typography variant="body2" style={{ marginLeft: '8px',fontFamily: 'Product-Sans-Regular', fontSize: '17px' }}>
                  342 N Water St,
                  Suit 600,
                  Milwaukee, <br/>WI - 53202,
                  USA.
                </Typography>
              </Box>
              <Box className={classes.iconTextContainer}>
                <PhoneIcon className={classes.icon} />
                <Typography variant="body2" style={{ marginLeft: '8px',fontFamily: 'Product-Sans-Regular', fontSize: '17px' }}>
                +1 (650) 787-4757
                </Typography>
              </Box>
              <Box className={classes.iconTextContainer}>
                <WhatsAppIcon className={classes.icon} />
                <Typography variant="body2" style={{ marginLeft: '8px',fontFamily: 'Product-Sans-Regular', fontSize: '17px' }}>
                {/* <Link href="https://wa.me/16507874757" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'inherit' }}>+1 (650) 787-4757</Link> */}
                +1 (650) 787-4757
                </Typography>
              </Box>
              <Box className={classes.iconTextContainer}>
                <MailIcon className={classes.icon} />
                <Typography variant="body2" style={{ marginLeft: '8px',fontFamily: 'Product-Sans-Regular', fontSize: '17px' }}>
                sales@ninjacaster.com
                </Typography>
              </Box>
            </Box>  
        </Grid> 
        <Grid item textAlign={'center'} sm={12} lg={7} sx={{'@media (max-width: 1199px)': {mt: 4,}}}>
          <Box className={classes.mediumBox}>
            <Box>
            <HeadingOne color="inherit" sx={{fontFamily: 'Adieu-Regular', fontWeight: 'normal'}}
              text="Order Form" />
            <Paper style={{ padding: '10px', backgroundColor:'transparent', boxShadow:'none' }}> 
            <form >
            <WhiteUnderlineTextField
              sx={{mt: 3}}
              type="text"
              label="Full Name"
              variant="standard"
              fullWidth
              InputLabelProps={{ style: { color: '#FFFFFF' } }}
              InputProps={{ style: { color: '#FFFFFF', backgroundColor:'transparent' } }}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              error={!!fieldErrors.name}
            />
            <FormHelperText style={{color:'#ffff00'}} error>
              {fieldErrors.name}
            </FormHelperText>
              <WhiteUnderlineTextField
                  sx={{mt: 3}}
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              InputLabelProps={{ style: { color: '#FFFFFF' } }}
              InputProps={{ style: { color: '#FFFFFF' } }}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!fieldErrors.email}
            />
            <FormHelperText error style={{color:'#ffff00'}}>{fieldErrors.email}</FormHelperText>
            <WhiteUnderlineTextField
                sx={{mt: 3}}
                type="tel" // Change the input type to "tel"
                label="Contact Number (With country code) "
                variant="standard"
                fullWidth
                InputLabelProps={{ style: { color: '#FFFFFF' } }}
                InputProps={{ style: { color: '#FFFFFF' } }}
                name="mobile" // Use "contactNumber" as the name
                value={formData.mobile} // Use the correct value from the state
                onChange={handleInputChange} // Use the onChange handler to update the state
                error={!!fieldErrors.mobile}
              />
              <FormHelperText error style={{color:'#ffff00'}}>{fieldErrors.mobile}</FormHelperText>
              <WhiteUnderlineTextField
                sx={{mt: 3}}
                type="text"
                label="Purpose"
                variant="standard"
                fullWidth
                InputLabelProps={{ style: { color: '#FFFFFF' } }}
                InputProps={{ style: { color: '#FFFFFF' } }}
                name="purpose"
                value={formData.purpose}
                onChange={handleInputChange}
                error={!!fieldErrors.purpose}
              />
              <FormHelperText error style={{color:'#ffff00'}}>{fieldErrors.purpose}</FormHelperText>
              <WhiteUnderlineTextField
                sx={{mt: 3}}
                type="text"
                label="Message"
                variant="standard"
                fullWidth
                InputLabelProps={{ style: { color: '#FFFFFF' } }}
                InputProps={{ style: { color: '#FFFFFF' } }}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                error={!!fieldErrors.message}
              />
              <FormHelperText error style={{color:'#ffff00'}}>{fieldErrors.message}</FormHelperText>
            <ButtonOne
              type="submit"
              text={isSubmitting ? <CircularProgress size={24} color="secondary" /> : "Submit"}
              style={{ border: '1px solid #FFF', color: '#FB4747', margin: '0 auto', height: '55px', display: 'block', marginTop: '50px', marginBottom: '10px' }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            {/* Display the success message if the submission was successful */}
            {successMessage && <Typography variant="body2" style={{ color: '#FFFFFF', textAlign:'center' }}>{successMessage}</Typography>}
            {/* Display the submission error message if there was an error */}
            {submissionError && <Typography variant="body2" style={{ color: '#FFFFFF', textAlign:'center' }}>{submissionError}</Typography>}
              </form>
            </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>  
    </Box> 
  );
};
import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './index.css';
import { HeadingOne } from '../../common/HeadingOne';

const Container = styled(Box)({
  backgroundColor: '#FFFFFF',
  color: '#00000'
});

const TestimonialCard = ({ text, imageUrl, name }) => {
  const ribbonStyle = {    
    position: 'absolute',
    width: '50px',
    height: '50px',
    backgroundColor: '#f5f5f5',
    bottom: '0',
    left: '0',    
    transform: 'translate(-50%, 50%) rotate(-45deg)'

  }
  return (
    <Card sx={{ margin: '0 20px', boxShadow: 'none', width: '95%', height: 'auto','@media (max-width: 540px)': {width: '100%', margin: 'auto'}  }}>
      <CardContent sx={{ padding: '0px' }}>
        <Box sx={{ borderRadius: '20px', borderBottomLeftRadius: '0px', padding: '30px', backgroundColor: '#f5f5f5',position: 'relative',}}>
          <Box style={ribbonStyle}></Box>
          <Box display={'grid'} sx={{ height: '158px', overflow: 'hidden' }}>
            <Typography variant="body1" gutterBottom style={{fontFamily: 'Product-Sans-Regular', fontSize: '1.1rem', textOverflow: 'ellipsis', display: '-webkit-box', '-webkit-line-clamp': '6', overflow: 'hidden', '-webkit-box-orient': 'vertical'}}>
              {text}
            </Typography>
          </Box>
        </Box>
        
        <CardHeader sx={{ textAlign: 'left', paddingTop: '0', paddingLeft: '0' }}
          avatar={
            <Avatar alt="Testimonial Avatar" src={imageUrl} sx={{ zoom: '1.6' , border: 'solid 4px #fff'}} />
          }
          title={
            <Typography variant="h5" component="span" color="secondary"
              sx={{ fontSize: '1.2rem', fontFamily: 'Adieu-Regular'}}>
                {name}
            </Typography>
          }
        />
      </CardContent>
    </Card>
  );
};


export const HomeContainer3 = () => {
  const theme = useTheme();

  // Dummy data for the carousel (you can replace it with your actual data)
  const testimonials = [
    {
      id: 1,
      text: "Many parents, grandparents, and coaches of players were very appreciative of the high-quality live streaming offered by Ninja Caster. They were able to have the pleasure of watching the young ones play even when they were not able to attend the games in person.",
      // imageUrl: "./img1.jpg",
      name: 'Ajay (Independence Cup Organizer)'
    },
    {
      id: 2,
      text: "It became a weekly routine for my parents to watch our game every Saturday evening on TV. They joke that they are seeing me play more than they used to when I was playing in India.",
      // imageUrl: "./img2.jpg",
      name: 'Joy (Midwest Cricket Tournament)'
    },
    {
      id: 3,
      text: "Thanks to Ninja Caster, we are able to analyze the game of our academy kids both in real-time and after the game to make needed adjustments and tailor-made practice sessions.",
      // imageUrl: "./img3.jpg",
      name: 'Talwinder Singh (Coach)'
    },
    // {
    //   id: 4,
    //   text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    //   imageUrl: "./img2.jpg",
    //   name: 'Shrimp'
    // },
    // {
    //   id: 5,
    //   text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   imageUrl: "./img3.jpg",
    //   name: 'Chorizo'
    // },
    // {
    //   id: 6,
    //   text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    //   imageUrl: "./img2.jpg",
    //   name: 'Shrimp'
    // },
    // {
    //   id: 7,
    //   text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   imageUrl: "./img3.jpg",
    //   name: 'Chorizo'
    // },
  ];

  const TestimonialCarousal = () => {
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const responsive = {
      0: { items: 1 },
      568: { items: 2 },
      1024: { items: 2.5 },
    };

    const getImagesList = () => {
      let arr = [];
      {testimonials.map((testimonial, index) => (
        // arr.push(<TestimonialCard key={index} text={testimonial.text} imageUrl={testimonial.imageUrl} name={testimonial.name} />)
        arr.push(<TestimonialCard key={index} text={testimonial.text} name={testimonial.name} />)
      ))}
      return arr;
    }

    return (
      <AliceCarousel
        mouseTracking
        items={getImagesList()}
        responsive={responsive}
        controlsStrategy="responsive"
        disableButtonsControls
        disableDotsControls
        ref={(el) => (carousel = el)}
      />
    );
  }

  const handlePrevSlide = () => {
    carousel.slideTo(carousel.slidePrev());
  };

  const handleNextSlide = () => {
    carousel.slideTo(carousel.slideNext());
  };

  let carousel; // Reference to the Carousel component
  return (
    <Box py={15} sx={{'@media (max-width: 540px)': {py: 7}}}>
      <Grid container justifyContent={'space-between'} sx={{pl: '5%', '@media (max-width: 540px)': {px: 3,}, '@media (max-width: 1199px)': {pl: 3,}}}>
        <Grid item xs={12} md={4} lg={3} sx={{'@media (max-width: 540px)': {textAlign: 'center'}}}>
          <HeadingOne color="secondary" sx={{fontSize: '2.5rem',fontFamily: 'Adieu-Regular', color: '#161526', '@media (max-width: 540px)': {fontSize: '1.75rem', mb: 5}}} text="Clients Share Their Success Stories" />
          <Box mt={6} sx={{'@media (max-width: 899px)': {mb: 4}}}>
            <button style={{backgroundColor: 'transparent',border: 'none', cursor: 'pointer'}} onClick={handlePrevSlide}><img src='../pre.svg' /></button>
            <button style={{backgroundColor: 'transparent',border: 'none', cursor: 'pointer',marginLeft: '30px'}}  onClick={handleNextSlide}><img src='../next.svg' /></button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <TestimonialCarousal />
        </Grid>
      </Grid>
    </Box>
  );
}

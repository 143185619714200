import * as React from 'react';
import { Header } from '../common/Header';
import { HomeContainer } from './HomeContainer';
import ScrollToTop from "../../components/ScrollToTop";

export const Home = () => {
    return (
        <>
            {/* ScrollToTop component inside App component */} 
            <ScrollToTop />
            <Header />
            <HomeContainer />
        </>
    )
}
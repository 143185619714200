import * as React from 'react';
import { Header } from '../common/Header';
import { Box } from '@mui/material';
import Footer from '../Home/Footer';
import { Contacts } from './Contacts';


import ScrollToTop from "../../components/ScrollToTop";

export const ContactUs = () => {
    return (
        <>
           {/* ScrollToTop component inside App component */} 
           <ScrollToTop />
           <Header />
           <Box sx={{ flexGrow: 1, mt: 10 }}>
                <Contacts/>
                <Footer />
            </Box>
        </>
    )
}
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './index.css'

export const ImagesSlider = ({images}) => {
    return (
        <Slide autoplay={true}>
            {images.map(item => (
                <div className="each-slide-effect">
                    <img src={item.imgSrc} />
                </div>
            ))}
        </Slide>
    );
};

import axios from "axios";
export const BASEURL = 'https://ccapi-test-origin.cricclubs.com'

export const getByUser = async (user) => {

    try {
        return axios.post(`${BASEURL}/CCAPI/contact/ninjacaster`, user);

        } catch (err) {
            throw new Error(err.message); 
          }

}
import * as React from 'react';

import OrderSummary1 from './OrderSummary1';
import Footer from '../Home/Footer';
import OrderDetailsHeader from './OrderDetailsHeader';
import ScrollToTop from "../../components/ScrollToTop";

export const OrderDetails = () => {
    return (
        <>
           {/* ScrollToTop component inside App component */} 
           <ScrollToTop />
           <OrderDetailsHeader />
           <OrderSummary1 />
           <Footer />
        </>
    )
}
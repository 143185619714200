import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './index.css'
import { HeadingOne } from '../../common/HeadingOne';
import { ImagesSlider } from '../../common/ImagesSlider';

const Container = styled(Box)({
  marginTop: '20px',
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  textAlign: 'left',
  boxShadow: 'none',
}));

// Styled component for the small grid image
const SmallImage = styled('img')({
  // height: '70px',
  maxHeight:'200px',
  display: 'block',
  width: 'auto', // Set the width for small images
  margin: '5px', // Add a small gap between images
  maxWidth:'250px',
  marginRight: '3%',
  background: '#fff',
  padding: '15px',
  borderRadius: '10px',
  border: 'solid 1px #e9e9e9',
});


const clients = [
  { imgSrc: './lions.png'},
  { imgSrc: './mlcy.png'},
  { imgSrc: './hcl.png'},
  { imgSrc: './mca.png'},
  { imgSrc: './mct.png'},
  { imgSrc: './mlc.png' },
  { imgSrc: './supernova.png'},
];

export const HomeComponent5 = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % clients.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + clients.length) % clients.length);
  };

  return (
    <Box py={15} sx={{'@media (max-width: 540px)': {pt: 7, pb: 4}, '@media (max-width: 1199px)': {px: 3}}}>
      <Grid container justifyContent={'space-between'} maxWidth={1170} marginX={'auto'}>
        <Grid item xs={12} md={12} sx={{textAlign: 'center', mb: 7}}>
          <HeadingOne color="secondary" sx={{fontSize: '2.5rem',fontFamily: 'Adieu-Regular', color: '#161526', '@media (max-width: 540px)': {fontSize: '1.75rem'}}} text="Our Trusted Clients" />              
        </Grid>

        {/* Display all small images in a grid */}
        <Grid item xs={12} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexWrap: 'wrap' }}>
          {clients.map((client, index) => ( 
            <SmallImage key={index} src={client.imgSrc} alt={client.altText} />
          ))}
        </Grid>
      </Grid>
      <Box className="mobile-slider">
        <ImagesSlider images={clients} />
      </Box>
    </Box>
  );
}

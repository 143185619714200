import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import BannerListItem from '../../common/BannerListItem';
import { HeadingOne } from '../../common/HeadingOne';

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none'
}));

const productDetails = {
  background: "url('../productBg.png') no-repeat",
  marginTop: '89px',
  paddingTop: '210px',
  paddingBottom: '100px',
  backgroundPosition: 'top right'
};


export default function ProductContainer1() {
  return (
    <>
    <Box style={productDetails}>
      <Grid container maxWidth={1170} marginX={'auto'} sx={{ '@media (max-width: 1199px)': { px: 3}} }>
        <Grid item xs={12} md={6} sx={{'@media (max-width: 540px)': {textAlign: 'center'}}}>
          <HeadingOne color="secondary" sx={{fontSize: '2.8rem',fontFamily: 'Adieu-Regular', '@media (max-width: 540px)': {fontSize: '2rem', mb: 5}}} text="Engage your friends, family, and fans around the world." />
          <Box>
            <List sx={{marginTop: '20px'}}>              
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '17px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      Attractive graphics on the videos similar to international games.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: 0, alignItems: 'center', marginBottom: '10px' }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '17px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      With real-time score updates.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: 0, alignItems: 'center', }}>
                <img src="../list.svg" style={{width: '15px', marginRight: '8px'}}  />
                <ListItemText sx={{ margin: 0, }}
                  primary={
                    <Typography variant="body2" component="span" color="primary" sx={{fontSize: '17px',fontFamily: 'Product-Sans-Regular','@media (max-width: 540px)': {fontSize: '1rem'}}}>
                      Automated custom graphics, sponsor logos, and ads in the live stream.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'right', position:'relative', width:'100%', height:'100%', overflow:'hidden',  '@media (max-width: 1199px)': {mt: 5}, '@media (max-width: 899px)': {position:'relative', left:'0%'}}}>
        <Box component="img"  src="../Full_Kit.webp" sx={{width: '120%', position:'relative', right:'0%', top:'0%', '@media (max-width: 899px)': {position:'relative', right:'0%', top:'10%', width:'110%'} }}  />
        </Grid>
      </Grid>
    </Box>
  </>
  );
}

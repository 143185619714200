import * as React from 'react';
import { Header } from '../common/Header';
import ProductContainer1 from './ProductContainer1';
import Productheader from './Headers';
import ProductContainer2 from './ProductConatiner2';
import ProductContainer3 from './ProductContainer3';
import ProductConatiner4 from './ProductContainer4';
import Footer from '../Home/Footer';
import { Box } from '@mui/material';
import ScrollToTop from "../../components/ScrollToTop";

export const ProductDetails = () => {
    return (
        <>
           {/* ScrollToTop component inside App component */} 
           <ScrollToTop />
           <Header />
           <Box sx={{ flexGrow: 1, mt: 10 }}>
                <ProductContainer1 />
                <ProductContainer2 />
                <ProductContainer3 />
                <ProductConatiner4 />
                <Footer />
            </Box>
        </>
    )
}
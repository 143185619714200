import React from 'react';
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    justifyContent: 'space-around',
    flexWrap: 'wrap',

    // Responsive styles for aligning items below each other
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  productBox: {
    display: 'flex',
    alignItems: 'center', // Align items vertically within the flex container
    justifyContent: 'center',
    '@media (max-width: 599px)': { textAlign:'center'},
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', // Display items in a column on small screens
    },
  },
  productImage: {
    marginRight: '30px', // Add margin to separate image from text
    maxWidth: '100%',
    height: 'auto',
    width: '60px',
    '@media (max-width: 1024px)': { marginRight:'15px'},
    '@media (max-width: 599px)': { marginRight:'0', marginBottom: '15px'},
  },
  productName: {
    marginTop: theme.spacing(1), // Add margin at the top of the label
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0, // Reset margin on small screens
      marginTop: theme.spacing(1), // Add margin at the top of the label
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1), // Add margin to separate text from image on larger screens
      marginTop: 0, // Reset margin on larger screens
    },
  },
}));

const ProductBox = ({ imageSrc, text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.productBox}>
      <img src={imageSrc} alt={text} className={classes.productImage} />
      <Typography color="white.main" className={classes.productName}>
        {text}
      </Typography>
    </Box>
  );
};

export default function ProductContainer2() {
  const classes = useStyles();
  const theme = useTheme();

  const borderPosition = {
    position: 'absolute',
    width: '28px',
    height: '28px',    
  }
  const topLeft = {
    ...borderPosition,
    top: '15px',
    left: '15px',
    borderTop: 'solid 2px #fff',
    borderLeft: 'solid 2px #fff',    
  }
  const topRight = {
    ...borderPosition,
    top: '15px',
    right: '15px',
    borderTop: 'solid 2px #fff',
    borderRight: 'solid 2px #fff',    
  }
  const bottomLeft = {
    ...borderPosition,
    bottom: '15px',
    left: '15px',
    borderBottom: 'solid 2px #fff',
    borderLeft: 'solid 2px #fff',    
  }
  const bottomRight = {
    ...borderPosition,
    bottom: '15px',
    right: '15px',
    borderBottom: 'solid 2px #fff',
    borderRight: 'solid 2px #fff',    
  }

  return (
    <Box bgcolor="secondary.main" sx={{padding:'60px 120px', position: 'relative', '@media (max-width: 1024px)': { padding:'60px'}}}>
      <Box style={topLeft} />
      <Box style={topRight} />
      <Box style={bottomLeft} />
      <Box style={bottomRight} />
      <Box className={classes.containerStyle} sx={{display:'grid', gridTemplateColumns: 'repeat(7, 1fr)', '@media (max-width: 1024px)': {gridTemplateColumns: 'repeat(4, 1fr)'}, '@media (max-width: 899px)': {gridTemplateColumns: 'repeat(2, 1fr)', 'gridGap': '30px'}}}>
        <ProductBox imageSrc="ninjaCaster.png" text="Ninja Caster" />
        <Divider orientation="vertical" flexItem sx={{background: '#C90202', width: '1px', margin: '0 auto', '@media (max-width: 1024px)': { display: 'none'}}} />
        <ProductBox imageSrc="tripod.png" text="Camera & a Tripod" />
        <Divider orientation="vertical" flexItem sx={{background: '#C90202', width: '1px', margin: '0 auto', '@media (max-width: 1024px)': { display: 'none'}}} />
        <ProductBox imageSrc="power-bank.png" text="Power Bank" />
        <Divider orientation="vertical" flexItem sx={{background: '#C90202', width: '1px', margin: '0 auto', '@media (max-width: 1024px)': { display: 'none'}}} />
        <ProductBox imageSrc="wifi.png" text="Internet Connection" />
      </Box>
      {/* <Box className={classes.containerStyle} sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Grid container>
          <Grid item xs={6} sx={{ display: 'grid', alignItems: 'end' }}>
            <ProductBox imageSrc="ninjaCaster.png" text="Ninja Caster" />
          </Grid>
          <Grid item xs={6} sx={{ display: 'grid', alignItems: 'end' }}>
            <ProductBox imageSrc="tripod.png" text="Camera & a Tripod" />
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item xs={6} sx={{ display: 'grid', alignItems: 'end' }}>
            <ProductBox imageSrc="power-bank.png" text="Power Bank" />
          </Grid>
          <Grid item xs={6} sx={{ display: 'grid', alignItems: 'end' }}>
            <ProductBox imageSrc="wifi.png" text="Wifi Connection" />
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
}

import React from 'react';
import './index.css';

const VideoFrame = () => {
  return (
    <div className="background-video-frame-container">
      <video autoPlay playsinline webkit-playsinline loop muted className="background-video-frame">
        <source src="/NinjaCaster-Website_2.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoFrame;
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Support = ({ imageSrc, label }) => {
  return (
    <Grid item>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
            src= {imageSrc}
            style={{ height: 20 , marginBottom: 5 }} 
         />
         <Typography variant="subtitle2" component="div">
          {label}
          </Typography>
          </Box>
    </Grid>
  );
};

export default function OrderDetailsHeader() {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToURL = (url) => {
    navigate(url, { replace: true });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" style={{ backgroundColor: theme.palette.white.main, color: theme.palette.primary.main, height: { xs: 64, md: 96 } }}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBlock: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
          {/* Web View: Image on the Left */}
          <Grid item xs={6} sm={6} md={6} lg={8}>
            <img src="/ninja-caster.png" style={{ width: '150px', cursor: 'pointer'}}
                onClick={() => navigateToURL(`/`)}/>
          </Grid>
  
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Grid container justifyContent="space-around">
              <Support imageSrc="./support.png" label="24/7 Support" />
              <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
              <Support imageSrc="./delivery.png" label="Global Delivery" />
              <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
              <Support imageSrc="./payments.png" label="Secured Payments" />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </Box>
  );
}

import { Typography } from '@mui/material';
import * as React from 'react';

export const HeadingOne = ({text, color, sx}) => {

    sx = {
        fontWeight: 600,
        fontSize: { xs: '1.6rem', sm: '2rem' },
        ...sx
    }
    
    return (
        <Typography variant="h4" component="div" color={color} sx={sx}>
            {text}
        </Typography>
    )
}
import React from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Box, Typography, Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';

const FooterBox = styled(Box)({
  backgroundColor: '#f5f5f5',
  marginTop: '30px'
});

const Image = styled('img')({
  width: '150px',
});

const SectionContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-evenly',
  flexWrap: 'wrap',
  marginTop: '16px',
  marginBottom: '26px',
  marginInline: '4%'
});

const Section = styled(Typography)({
  textAlign: 'left',
  color: '#FB4747',
  fontFamily: 'Adieu-Regular'
});

const SubSection = styled(Box)({
  display: 'block',
  marginTop: '20px',
  textAlign: 'left',
  cursor: 'pointer',
  '@media (max-width: 540px)': {marginTop: "10px"}
});

const SocialMediaContainer = styled(Box)(({ theme }) => ({
  // display: 'flex',
  marginTop: '16px',
  marginLeft: '-8px',
  textAlign: 'left',
  display: 'flow-root',
  width: '250px',
  overflow: 'auto',
  lineHeight: '2',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '250px',
    lineHeight: '0'
  }
}));

const SocialMediaIcon = styled(Box)({
  margin: '4px 8px',
  color: '#FB4747',
  width: '25px',
  display: 'inline-block'
});

const CopyRights = styled(Typography)({
  marginTop: '16px',
  textAlign: 'center',
  marginTop: '40px'
});

export default function Footer() {
  return (
    <FooterBox sx={{padding: '120px 20px 60px', '@media (max-width: 540px)': {padding: '60px 24px 40px'}}}>
      <Grid container maxWidth={1170} marginX={'auto'} mb={5}>
        <Grid item xs={12} sm={4} md={4} style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Image src="./ninja-caster.png" alt="Logo" style={{marginBottom: '10px', width:'220px'}} />
          <Box>
            {/* <Typography variant="body2" component="span" sx={{color: '#161526', fontSize: '14px', lineHeight: '22px', fontFamily:  'Product-Sans-Regular'}}>Lorem ipsum odor amet, consectetuer adipiscing elit Imperdiet erat nullam tortor quis elit lacus blandit vitae. Nostra dapibus bibendum.</Typography> */}
          </Box>
          {/* <SocialMediaContainer>
            <SocialMediaIcon>
              <InstagramIcon />
            </SocialMediaIcon>
            <SocialMediaIcon>
              <FacebookIcon />
            </SocialMediaIcon>
            <SocialMediaIcon>
              <TwitterIcon />
            </SocialMediaIcon>
            <SocialMediaIcon>
              <YouTubeIcon />
            </SocialMediaIcon>
            <SocialMediaIcon>
              <LinkedInIcon />
            </SocialMediaIcon>
            <SocialMediaIcon>
              <WhatsAppIcon />
            </SocialMediaIcon>
          </SocialMediaContainer> */}
        </Grid>
        <Grid item xs={12} sm={2} md={2} sx={{ml: 'auto', '@media (max-width: 540px)': {mt: 4}}}>
          <Section variant="subtitle1">Information</Section>
          <SubSection>
            {/* <Typography variant="body2" sx={{mb: 1,fontFamily: 'Product-Sans-Regular'}}>Home</Typography> */}
            <Link to="/" style={{ textDecoration: 'none', textAlign:'start', color:'black' }}>
               <Typography variant="body2" sx={{ lineHeight: '2' }}>
                 Home
               </Typography>
	          </Link>
            <Link to="/product" style={{ textDecoration: 'none', textAlign:'start',color:'black' }}>
               <Typography variant="body2">Ninja Caster</Typography>
	             </Link>
            {/* <Typography variant="body2"  sx={{fontFamily: 'Product-Sans-Regular'}}>Ninja Caster</Typography> */}
          </SubSection>
        </Grid>
        <Grid item xs={12} sm={2} md={2} sx={{ml: 'auto', '@media (max-width: 540px)': {mt: 4}}}>
          <Section variant="subtitle1">Legal</Section>
          <SubSection>
            {/* <Typography variant="body2" sx={{mb: 1,fontFamily: 'Product-Sans-Regular'}}>Terms and Conditions</Typography>
            <Typography variant="body2"  sx={{fontFamily: 'Product-Sans-Regular'}}>Privacy Policy</Typography> */}
            <Typography variant="body2" sx={{ lineHeight: '2' }}>
               <a
                 href="https://cricclubs.com/terms.do"
                 target="_blank"
                 style={{ textDecoration: 'none', color: 'black' }}
               >
                 Terms and Conditions
               </a>
             </Typography>
             <Typography variant="body2">
               <a
                 href="https://cricclubs.com/privacy.do"
                 target="_blank"
                 style={{ textDecoration: 'none', color: 'black' }}
               >
	                 Privacy Policy
               </a>
             </Typography>
          </SubSection>
        </Grid>
        <Grid item xs={12} sm={2} md={2} sx={{ml: 'auto', '@media (max-width: 540px)': {mt: 4}}}>
          <Section variant="subtitle1">Support</Section>
          <SubSection>
            {/* <Typography variant="body2" sx={{mb: 1,fontFamily: 'Product-Sans-Regular'}}>About Us</Typography> */}
            {/* <Typography variant="body2"  sx={{fontFamily: 'Product-Sans-Regular'}}>Contact Us</Typography> */}
            <Link to="/contact" style={{ textDecoration: 'none', textAlign:'start',color:'black' }}>
                 <Typography variant="body2">Contact Us</Typography>
	          </Link>
          </SubSection>
        </Grid>
      </Grid>
      
      <Divider />
      <CopyRights variant="body2">
        {"© Copyrights "} {new Date().getFullYear()} Ninja Caster. All rights reserved.
      </CopyRights>
    </FooterBox>
  );
}
